<template>
  <b-card class="mt-5" v-if="slides !== null">
    <div class="card-title" style="display: flex">
      <div class="data-shop-header">
        {{ title ? title : $t("DATA_SHOP.MODELS") }}
      </div>
      <div style="margin-left: auto; order: 2" v-if="slides.length > 1">
        <button
          type="button"
          :disabled="page === 0"
          :class="{
            btn: true,
            'btn-outline-pagination': page !== 0,
            'btn-outline-pagination-disabled': page === 0,
          }"
          @click="clickLeft"
        >
          &lt;
        </button>
        <button
          :disabled="page === slides.length - 1"
          :class="{
            'ml-2': true,
            btn: true,
            'btn-outline-pagination': page !== slides.length - 1,
            'btn-outline-pagination-disabled': page === slides.length - 1,
          }"
          type="button"
          @click="clickRight"
          style="margin-left: auto; order: 3"
        >
          >
        </button>
      </div>
    </div>

    <b-overlay :show="showOverlay">
      <Carousel
        :hide-arrows="true"
        ref="carousel"
        v-if="slides && slides.length > 0"
        @page="pageChanged"
      >
        <Slide v-for="slide in slides" :key="slide.key">
          <div class="row w-100" style="margin: 0 !important">
            <div
              class="col-lg-6 d-flex align-items-stretch"
              v-for="card in slide.items"
              :key="card.id"
            >
              <div class="card border-secondary h-100">
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-2" style="margin: auto">
                      <img
                        v-on:click.prevent="showClicked(card.id)"
                        :src="
                          card.image ? card.image : '/media/logos/natlogger.png'
                        "
                        style="
                          max-width: 100%;
                          max-height: 100%;
                          cursor: pointer;
                        "
                      />
                    </div>
                    <div class="col-md-10 px-3">
                      <div class="card-block px-6">
                        <a
                          :href="'/shop/ml-model/' + card.id"
                          class="data-shop-inner-header link-blue-hover"
                          v-on:click.prevent="showClicked(card.id)"
                        >
                          {{ card.name }}
                        </a>
                        <div class="data-shop-card-text mt-2">
                          {{ card.descriptionShort }}
                        </div>
                        <div
                          class="data-shop-author mt-5"
                          style="display: flex; align-items: center"
                        >
                          <ul
                            class="u-star-rating-20"
                            :title="
                              $t('DATA_SHOP.RATING', {
                                rating: card.rating,
                                votes: card.votesCount,
                              })
                            "
                          >
                            <li
                              class="u-current-rating"
                              :style="`width:${(card.rating / 5) * 100}%`"
                            ></li>
                          </ul>
                          <template v-if="card.authorName">
                            <img class="ml-5" src="/media/misc/profile.svg" />
                            <div class="ml-2">{{ card.authorName }}</div>
                          </template>
                          <b-button
                            class="btn-open-pc"
                            style="margin-left: auto; order: 2"
                            variant="success"
                            @click="showClicked(card.id)"
                            >{{ $t("DATA_SHOP.SHOW") }}</b-button
                          >
                        </div>
                        <b-button
                          class="btn-open-mobile mt-2"
                          variant="success"
                          @click="showClicked(card.id)"
                          >Смотреть</b-button
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Slide>
      </Carousel>
    </b-overlay>
  </b-card>
</template>
<script>
import KTUtil from "@/assets/js/components/util";
import { Carousel, Slide } from "vue-snap";
import "vue-snap/dist/vue-snap.css";
import axios from "axios";
import { mapState } from "vuex";

export default {
  name: "mlModels",
  components: {
    Carousel,
    Slide,
  },

  props: {
    title: {
      type: String,
      default: null,
    },

    excludeItem: {
      default: null,
    },
  },

  data: function () {
    return {
      slides: [],
      page: 0,
      showOverlay: true,
    };
  },

  computed: {
    itemsPerPage() {
      return KTUtil.isMobileDevice() === true ? 1 : 2;
    },

    ...mapState({
      defaultTeam: (state) => state.team.defaultTeam,
    }),
  },

  created: async function () {
    if (this.excludeItem != null) {
      this.slides = null; // пока не отображаем этот компонент вовсе
      // т.к может быть единственный слайд, в котором есть исключенный айтем
    }

    let params = {
      teamId: this.defaultTeam.id,
    };

    let result = await axios.get("/api/ml-model/available", {
      params: params,
    });

    let items = result.data;

    if (this.excludeItem != null) {
      items = items.filter((item) => item.id != this.excludeItem);
    }

    for (let i = 0; i < items.length; i++) {
      items[i].descriptionShort = this.makeShortDescription(
        items[i].description
      );
    }

    this.slides = this.createSlides(items);
    if (this.slides.length === 0) this.slides = null;

    this.showOverlay = false;
  },

  methods: {
    makeShortDescription(desc) {
      let length = 120;
      if (desc.length < length) return desc;

      return desc.substring(0, length) + "...";
    },

    pageChanged: function (event) {
      this.page = event.currentPage;
    },

    createSlides: function (items) {
      let slides = [];
      let slidesTotal =
        KTUtil.isMobileDevice() === true
          ? items.length
          : Math.ceil(items.length / 2);
      for (let i = 0; i < slidesTotal; i++) {
        let slide = {
          items: [],
        };

        for (let item = 0; item < this.itemsPerPage; item++) {
          let index = i * this.itemsPerPage + item;
          if (index >= items.length) continue;
          slide.items.push(items[index]);
        }

        slides.push(slide);
      }

      return slides;
    },

    clickRight: function () {
      this.$refs.carousel.changeSlide(1);
    },

    clickLeft: function () {
      this.$refs.carousel.changeSlide(-1);
    },

    showClicked: function (id) {
      this.$router.push({ name: "buy-model", params: { id: id } });
    },
  },
};
</script>
<style>
@media screen and (min-width: 1062px) {
  .btn-open-pc {
    display: inline-block;
  }
  .btn-open-mobile {
    display: none;
  }
}
@media screen and (max-width: 1061px) {
  .btn-open-pc {
    display: none;
  }
  .btn-open-mobile {
    display: inline-block;
  }
}
</style>
